<script setup>
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

const { locale, setLocale } = useI18n(); // use as global scope

const route = useRoute();
const nuxtApp = useNuxtApp();

const langs = [
  {
    value: "bg",
    name: "Български",
    icon: "fi fi-bg",
  },
  {
    value: "hr",
    name: "Hrvatski",
    icon: "fi fi-hr",
  },
  {
    value: "cs",
    name: "čeština",
    icon: "fi fi-cz",
  },
  {
    value: "da",
    name: "Dansk",
    icon: "fi fi-dk",
  },
  {
    value: "nl",
    name: "Nederlands",
    icon: "fi fi-nl",
  },
  {
    value: "en",
    name: "English",
    icon: "fi fi-us",
  },
  {
    value: "et",
    name: "Eesti keel",
    icon: "fi fi-ee",
  },
  {
    value: "fi",
    name: "Suomi",
    icon: "fi fi-fi",
  },
  {
    value: "fr",
    name: "Français",
    icon: "fi fi-fr",
  },
  {
    value: "de",
    name: "Deutsch",
    icon: "fi fi-de",
  },
  {
    value: "el",
    name: "ελληνικά ",
    icon: "fi fi-gr",
  },
  {
    value: "hu",
    name: "Magyar",
    icon: "fi fi-hu",
  },
  {
    value: "ga",
    name: "Gaeilge",
    icon: "fi fi-ie",
  },
  {
    value: "it",
    name: "Italiano",
    icon: "fi fi-it",
  },
  {
    value: "lv",
    name: "Latviski",
    icon: "fi fi-lv",
  },
  {
    value: "lt",
    name: "Lietuvių",
    icon: "fi fi-lt",
  },
  {
    value: "mt",
    name: "Malti",
    icon: "fi fi-mt",
  },
  {
    value: "pl",
    name: "Polski",
    icon: "fi fi-pl",
  },
  {
    value: "pt",
    name: "Português",
    icon: "fi fi-pt",
  },
  {
    value: "ro",
    name: "Română",
    icon: "fi fi-ro",
  },
  {
    value: "sk",
    name: "Slovenský",
    icon: "fi fi-sk",
  },
  {
    value: "sl",
    name: "Slovenščina",
    icon: "fi fi-si",
  },
  {
    value: "es",
    name: "Español",
    icon: "fi fi-es",
  },
  {
    value: "sv",
    name: "Svenska",
    icon: "fi fi-se",
  },
];

const setLanguage = async (l) => {
  const baseRoute = nuxtApp.$getRouteBaseName(route);
  await navigateTo(nuxtApp.$localePath(baseRoute, l));
};
</script>

<template>
  <div class="top-lang">
    <Multiselect
      :key="route.path"
      :value="locale"
      @change="setLanguage"
      label="name"
      :options="langs"
      :caret="false"
      :can-clear="false"
      :clear-on-select="false"
    >
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          <div>{{ value.value }}</div>
          <div :class="value.icon" />
        </div>
      </template>

      <template v-slot:option="{ option }">
        <span :class="option.icon" />
        <span class="ml-2">{{ option.name }}</span>
      </template>
    </Multiselect>
  </div>
</template>

<style>
.multiselect-single-label {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.multiselect {
  width: 69px;
  padding: 0px 10px 0px 13px;
}

.multiselect-dropdown {
  height: 50vh !important;
  max-height: 50vh !important;
  left: unset;
  right: 0px;

  @media screen and (min-width: 768px) {
    width: 200px;
  }
  @media screen and (max-width: 767px) {
    left: 0 !important;
    right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: fixed !important;
    top: -50vh !important;
  }
}

.multiselect-single-label {
  display: flex !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  text-transform: uppercase;

  width: 100%;
  justify-content: space-between;
}

.top-lang {
  margin-left: auto;
}

.fi {
  border-radius: 2px;
}
</style>
